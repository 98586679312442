<!--
jarvis-field-label 컴포넌트: 필드 라벨표시
[속성]
	 required: (String|Boolean) 필수여부, 필수이면 빨간색 *(bullet)표시
[사용방법]
  <jarvis-field-label>라벨명</jarvis-field-label>
  <jarvis-field-label required="true">라벨명</jarvis-field-label>
  <jarvis-field-label :required="isRequired">라벨명</jarvis-field-label>
-->
<template>
	<label class="col-lg-3 control-label" style="padding-right: 5px">
		<slot></slot>
		:
		<span class="text-danger" v-if="requiredAsBoolean">*</span>
	</label>
</template>

<script>
export default {
	props: {
		//필수여부 (Boolean: true/false, String:'true'/'t'/'1' 이외에 false)
		required: { type: [String, Boolean], required: false, default: false },
	},
	computed: {
		requiredAsBoolean() {
			return this.$jarvisExtention.converter.asBoolean(this.required);
		},
	},
};
</script>
